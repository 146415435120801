<template>
  <panel-message>
    <h1 class="text-mid-big text-center">
      Welcome to {{ domainInfo.name }} 👋
    </h1>

    <!-- <ul class="custom-bullets"> -->
    <!-- <li class="discover"> -->
    <div class="custom-bullets">
      <div class="point discover">
        <div class="icon">🔍&nbsp;&nbsp;</div>
        <div>
          Discover your ideal chatting partner: each
          <span class="new-chat"
            ><i class="fa-solid fa-refresh"></i>&nbsp;<strong
              >New
            </strong></span
          >
          chat generates a <strong>unique AI</strong> with distinct personality,
          interests, and hobbies.
        </div>
      </div>
      <!-- </li> -->
      <!-- <li class="manager"> -->
      <div class="point manager">
        <div class="icon">🗂️&nbsp;&nbsp;</div>

        <div>
          <span>
            Manage your AI friends in your
            <strong>AI friends panel.</strong></span
          >
        </div>
        <div class="friends-btn">
          <img class="people-icon" src="@/assets/g6.svg" alt="Custom Icon" />
        </div>
      </div>
      <!-- </li> -->
      <!-- <li class="save"> -->
      <div class="point save">
        <div class="icon">💾&nbsp;&nbsp;</div>

        <div>
          Found someone you like? <strong>Save them!</strong> Continue chatting
          later—they'll remember your previous conversations.
          <!-- </li> -->
        </div>
      </div>
      <div class="point free">
        <div class="icon">🎉&nbsp;&nbsp;</div>

        <div>
          <!-- <li class="free"> -->
          Enjoy <strong>free chatting</strong> time every day, plus rewards when
          you engage!
        </div>
      </div>
      <!-- </li> -->
      <!-- </ul> -->

      <!-- <h2>The Omegle Alternative for the Future 🚀</h2> -->
      <!-- <p class="text-welcome">
        Dive into a world of unique interactions with a different AI character
        every time you chat. Each AI is crafted on-the-spot, boasting its own
        distinct personality, interests, and hobbies 🎨🎬🎺♟️🎳
      </p>

      <p>
        <strong
          >{{ $getDomainSpecificInfo().name }} isn't just another GPT-4 wrapper; it's the most advanced AI
          chatting system and AI character generator available today!
        </strong>
      </p>
      <p class="text-welcome">
        Whether you're seeking advice, looking to unwind, or in need of a
        listening ear, your perfect AI conversational partner awaits. Experience
        the future of digital companionship with our cutting-edge AI friend
        technology 🤗
      </p>
      <p class="text-welcome">
        Sign in today and receive complimentary minutes to chat with as many AI
        characters as you like. Additional time can be purchased at an
        advantageous rate, with no subscription required—only one-time payments
        💫!
      </p> -->
    </div>
    <div>
      <strong>
        All chats are completely private and secure. We do not access or monitor
        them!
      </strong>
    </div>
    <p class="text-mid-big"><strong>Have fun😄!</strong><br /></p>
    <base-button class="btn-next" @click="$emit('close')">Will do!</base-button>
  </panel-message>
</template>

<script>
import BaseButton from "./BaseButton.vue";
import PanelMessage from "./PanelMessage.vue";
export default {
  components: {
    BaseButton,
    PanelMessage,
  },
  computed: {
    domainInfo() {
      return this.$getDomainSpecificInfo();
    },
  },
};
</script>

<style scoped>
.point {
  display: flex;
  align-items: center;
}
.custom-bullets {
  text-align: left;
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: clamp(2px, 1.5dvh, 20px);
}

.text-welcome {
  align-self: center;
  text-align: center;
  text-justify: inter-word;
  hyphens: auto;
}

.new-chat {
  /* background-color: var(--cta-color); */
  background-image: radial-gradient(
    ellipse at center,
    #ffa826c9,
    rgba(255, 255, 255, 0)
  );
  white-space: nowrap;
  display: inline-flex;
  /* border-radius: 20px; */
  padding-top: clamp(0rem, 0.5dvh, 5rem);
  padding-bottom: clamp(0rem, 0.5dvh, 5rem);
  padding-right: clamp(0rem, 1dvw, 5rem);
  padding-left: clamp(0rem, 1dvw, 5rem);
}

.manager {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.friends-btn {
  background-image: radial-gradient(
    ellipse at center,
    #ffa826c9,
    rgba(255, 255, 255, 0)
  );
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  /* padding-top: clamp(0rem, 0.5dvh, 5rem);
  padding-bottom: clamp(0rem, 0.5dvh, 5rem);
  padding-right: clamp(0rem, 1dvw, 5rem);
  padding-left: clamp(0rem, 1dvw, 5rem); */
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 50%;
}
.people-icon {
  object-fit: contain;
  display: block;
  width: 35px;
}
</style>
