import { createApp } from "vue";
import { createPinia } from "pinia";
import VueSocialSharing from "vue-social-sharing";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import { useSharedInfoStore } from "@/store/sharedInfoStore";

const app = createApp(App);
app.use(VueGtag, {
  config: {
    id: "G-VBSWGSWG8S",
    params: {
      linker: {
        domains: ["omegleai.com", "aipeeps.org", "theaipeeps.com"],
      },
    },
  },
});
const getDomainSpecificInfo = () => {
  let currentDomain = window.location.hostname;
  if (currentDomain.includes("aipeeps.org")) {
    return {
      name: "AI peeps",
      url: "https://AIpeeps.org",
      email: "youraipeeps@gmail.com",
    };
  } else if (
    currentDomain.includes("theaipeeps.com") ||
    currentDomain.includes("localhost")
  ) {
    return {
      name: "The AI Peeps",
      url: "https://theaipeeps.com",
      email: "theaipeeps@gmail.com",
    };
  } else if (currentDomain.includes("omegleai.com")) {
    return {
      name: "OmegleAI",
      url: "https://omegleAI.com",
      email: "myomegleai@gmail.com",
    };
  }
};

const pinia = createPinia();
app.use(VueSocialSharing);
app.use(pinia);
app.config.globalProperties.$getDomainSpecificInfo = getDomainSpecificInfo;
const sharedInfoStore = useSharedInfoStore();
sharedInfoStore.fetchSharedInfo().then(() => {
  app.mount("#app");
});
