// src/stores/user.js
import { defineStore } from "pinia";
import { onAuthStateChanged, signInAnonymously, signOut } from "firebase/auth";
import { useSharedInfoStore } from "@/store/sharedInfoStore";

import { auth } from "@/services/firebase";
import {
  userEmail,
  generateUniqueNumber,
  needHelpSpeechBubble,
} from "@/services/userService";
export const useUserStore = defineStore("user", {
  state: () => ({
    userRecord: {},
    saved_ai_personas: [],
    locFreeSecsLeft: 0,
    locPaidSecsLeft: 0,
    websocket: null,
    authReadyFromServer: false,
    userSessionAlready: false,
    allReady: false,
    volumePerc: 50,
    savedConvo: null,
    current_agent_id: null,
    ai_bio_info: null,
    current_agent_images: null,
    pingInterval: null,
    current_agent_chat_name: "",
    timer_speech_bubble_custom_ai: null,
  }),
  methods: {},
  getters: {
    currentIsSaved: (state) => {
      return state.saved_ai_personas.some(
        (item) => item.persona_id === state.current_agent_id
      );
    },
    maxNewAIdaily: (state) => state.userRecord?.maxNewAIdaily ?? null,

    getGracePeriodEnds: (state) => state.userRecord?.gracePeriodEndsOn ?? null,
    getNumAIgenerated: (state) => state.userRecord?.numAIgenerated ?? 0,
    getEmail: (state) => state.userRecord?.email ?? "",
    getTimeWhenSharedFB: (state) =>
      state.userRecord?.getTimeWhenSharedFB ?? false,
    getTimeWhenSharedX: (state) =>
      state.userRecord?.getTimeWhenSharedX ?? false,
    displayName: (state) => state.userRecord?.displayName ?? "",
    isAnonymous: (state) => state.userRecord?.isAnonymous ?? true,
    isSubscribed: (state) => state.userRecord?.isSubscribed ?? false,
    unlockedAll: (state) => state.userRecord?.unlockedAll ?? false,
    unlockedOptions: (state) => state.userRecord?.unlockedOptions ?? [],
    user_uid: (state) => state.userRecord?.uid ?? null,
    totSecondsLeft: function () {
      return this.locFreeSecsLeft + this.locPaidSecsLeft;
    },
  },

  actions: {
    async fetchSavedPersonas() {
      if (this.isAnonymous) {
        return null;
      }
      this.nicknames = []; // Reset before fetching new data
      const token = await auth.currentUser.getIdToken(true);

      const response = await fetch(
        `${process.env.VUE_APP_URL}/api/get_saved_personas`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      this.saved_ai_personas = await response.json();
    },
    incrementNumAIgenerated() {
      if (this.userRecord) {
        this.userRecord.numAIgenerated += 1;
      }
    },
    sendPing() {
      // Ensure the socket is open before sending a message
      if (this.websocket.readyState === WebSocket.OPEN) {
        this.websocket.send(JSON.stringify({ type: "ping" }));
      }
    },
    async cleanupWebSocket() {
      if (this.websocket) {
        // Create a promise to await the closure
        await new Promise((resolve) => {
          this.websocket.onclose = () => {
            console.log("WebSocket closed (from cleanup).");
            clearInterval(this.pingInterval);
            this.websocket = null;
            resolve();
          };

          // Initiate the close handshake
          this.websocket.close();
        });
      }
    },
    async initializeWebsocket() {
      await this.cleanupWebSocket();

      const session_uid = generateUniqueNumber();
      const websocket_url = `${process.env.VUE_APP_WEBSOCKET_URL}${session_uid}`;

      console.log(websocket_url);
      return new Promise((resolve, reject) => {
        this.websocket = new WebSocket(websocket_url);
        this.websocket.onopen = async () => {
          this.pingInterval = setInterval(this.sendPing, 30000);
          resolve();
        };
        this.websocket.onclose = () => {
          clearInterval(this.pingInterval);
        };
        this.websocket.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.type) {
            switch (data.type) {
              case "update_user_record":
                this.updateUserRecord(data.record);
                break;
              case "auth_ready":
                this.authReadyFromServer = true;
                this.savedConvo = data.saved_convo;
                this.current_agent_id = data.saved_ai_id;
                this.fetchSavedPersonas();
                this.current_agent_images = data.ai_images;
                this.current_agent_chat_name = data.ai_name;
                this.ai_bio_info = data.ai_bio_info;
                // if (this.savedConvo) {
                //   this.chatStore.restoreSavedConvo(true);
                // }
                break;
              case "update_time_left":
                this.setLocSecsLeft(data.free_secs, data.paid_secs);
                break;
              case "user_session_already":
                this.userSessionAlready = true;
                this.allReady = false;
                needHelpSpeechBubble();

                this.websocket.close();
                break;
            }
          }
        };
        this.websocket.onerror = (error) => {
          console.error("WebSocket Error:", error);
          reject(error);
        };
      });
    },
    decrementFreeSeconds() {
      if (this.locFreeSecsLeft > 0) {
        this.locFreeSecsLeft--;
        if (this.isAnonymous) {
          let secondsLeft = Number(localStorage.getItem("secondsLeft") || 0);
          secondsLeft = Math.max(secondsLeft - 1, 0); // Ensure it doesn't go below 0
          localStorage.setItem("secondsLeft", secondsLeft);
        }
      }
    },
    decrementPaidSeconds() {
      if (this.locPaidSecsLeft > 0) {
        this.locPaidSecsLeft--;
      }
    },
    decrementTime() {
      if (this.locFreeSecsLeft > 0) {
        this.decrementFreeSeconds();
      } else {
        this.decrementPaidSeconds();
      }
    },

    setLocSecsLeft(freeSeconds, paidSeconds) {
      this.locFreeSecsLeft = freeSeconds;
      this.locPaidSecsLeft = paidSeconds;
    },
    setUserRecord(record) {
      this.userRecord = record;
    },
    updateUserRecord(record) {
      if (!this.userRecord) {
        this.userRecord = {};
      }
      for (const [key, value] of Object.entries(record)) {
        this.userRecord[key] = value;
      }
    },

    async initializeAuth() {
      onAuthStateChanged(auth, async (user) => {
        this.authReadyFromServer = false;

        if (user) {
          if (user.isAnonymous && !("secondsLeft" in localStorage)) {
            localStorage.setItem(
              "secondsLeft",
              useSharedInfoStore().sharedInfo.starting_secs_anon
            );
          }
          const token = await auth.currentUser.getIdToken(true);
          //   this.currentAuthUser = auth.currentUser;
          this.websocket.send(
            JSON.stringify({
              type: "authenticate",
              email: userEmail,
              auth_token: token,
              seconds_left: localStorage.getItem("secondsLeft"),
            })
          );
        } else {
          await signInAnonymously(auth);
        }
      });
    },

    // // }
    // async signInAnonymously() {
    //   try {
    //     const result = await signInAnonymously(auth);
    //     await this.setAuthUser(result.user); // Make sure this method is suitable for async operations
    //     await this.createAnonymousUserRecord(result.user);
    //   } catch (error) {
    //     console.error("Anonymous sign-in failed:", error);
    //   }
    // },

    // Logout Function
    async logout() {
      //   this.currentAuthUser = null;
      this.idToken = null;
      await signOut(auth); // This will trigger onAuthStateChanged and sign in anonymously
    },
  },
});
