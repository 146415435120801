<template>
  <div id="speech-bubble-container">
    <p id="speech-bubble" class="text-small"></p>
  </div>
</template>
<script>
export default {
  methods: {},
};
</script>
<style scoped>
#speech-bubble-container {
  position: absolute;
  padding-right: 15px;

  opacity: 0;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}
#speech-bubble-container.visible {
  opacity: 1;
  visibility: visible;
  z-index: 1002;
}
#speech-bubble {
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  padding: 10px 15px;
  text-align: left;
  position: relative;
}
/* Tail pointing upwards (default) */
#speech-bubble.top::after {
  content: "";
  position: absolute;
  bottom: 100%; /* Tail at the top */
  left: 20px; /* Adjust to control horizontal position */
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #007bff; /* Triangle pointing up */
  border-top: 0;
  margin-left: -10px;
  margin-bottom: -1px;
}

/* Tail pointing downwards */
#speech-bubble.bottom::after {
  content: "";
  position: absolute;
  top: 100%; /* Tail at the bottom */
  left: 20px; /* Adjust to control horizontal position */
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #007bff; /* Triangle pointing down */
  border-bottom: 0;
  margin-left: -10px;
  margin-top: -1px;
}

/* Tail pointing to the left */
#speech-bubble.left::after {
  content: "";
  position: absolute;
  right: 100%; /* Tail on the left */
  top: 10px; /* Adjust to control vertical position */
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: #007bff; /* Triangle pointing left */
  border-left: 0;
  margin-top: -10px;
  margin-right: -1px;
}

/* Tail pointing to the right */
#speech-bubble.right::after {
  content: "";
  position: absolute;
  left: 100%; /* Tail on the right */
  top: 20px; /* Adjust to control vertical position */
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left-color: #007bff; /* Triangle pointing right */
  border-right: 0;
  margin-top: -10px;
  margin-left: -1px;
}
.hidden {
  display: none;
}
</style>
