<template>
  <div class="standard-screen" @click="closeStandardScreen">
    <div class="standard-content" @click.stop>
      <!-- Default content as a fallback -->
      <slot>
        <div>
          <h1>Title!</h1>
          <p>Message</p>
        </div>
      </slot>
      <base-button
        v-if="backButton"
        @click="$emit('close')"
        class="close"
        :icon="'fa-solid fa-arrow-left'"
        :icon-position="'left'"
        :when-to-shrink="0"
        >&nbsp;&nbsp;Back</base-button
      >
    </div>
  </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";

export default {
  components: {
    BaseButton,
  },
  props: {
    backButton: {
      type: Boolean,
      default: false,
    },
    closeOnClickOut: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { closeOnClick: this.closeOnClickOut };
  },
  methods: {
    closeStandardScreen() {
      if (this.closeOnClick) {
        this.$emit("close");
      }
    },
  },
  mounted() {},

  beforeUnmount() {},
};
</script>

<style scoped>
.standard-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  z-index: 99;
  /* color: var(--intro-text-color); */
}

.standard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: clamp(5px, 5dvw, 40px);
  gap: clamp(10px, 1dvw, 20px);
  background: rgba(255, 255, 255, 1);
  border-radius: 15px;
  box-shadow: 0 4px 16px var(--cta-color);
  overflow-y: auto;
  --max-width: 90dvw;
  max-width: var(--max-width);
  max-height: 90dvh;
}

@media (max-width: 1100px) {
  .standard-content {
    max-width: 70dvw;
  }
}

@media (max-width: 800px) {
  .standard-content {
    max-width: 70dvw;
  }
}
@media (max-width: 600px) {
  .standard-content {
    max-width: 80dvw;
  }
}
@media (max-width: 400px) {
  .standard-content {
    max-width: 90dvw;
  }
}
</style>
