import { auth } from "@/services/firebase"; // Adjust path as necessary
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

export async function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  try {
    // Only sign in the user and let the onAuthStateChanged handle the Firestore document.
    await signInWithPopup(auth, provider);
    console.log("Sign-in with Google was successful.");
    return true;
  } catch (error) {
    console.error("Error during Google sign-in:", error.message);
    return false;
  }
}

import {
  TwitterAuthProvider,
  fetchSignInMethodsForEmail,
  linkWithCredential,
} from "firebase/auth";

export let userEmail = null; // Store email here

export async function signInWithTwitter() {
  const provider = new TwitterAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    console.log("Sign-in with Twitter was successful.");
    const additionalUserInfo = result.additionalUserInfo;
    const profile = additionalUserInfo.profile;
    userEmail = profile.email;
    // Assuming email will always be provided (not always the case in real-world scenarios)
    console.log(`User's email: ${result.user.email}`);
  } catch (error) {
    console.error("Error during Twitter sign-in:", error.message);
    // TODO: This part currently doesn't work because the fetchSignInMethodsForEmail returns an empty list
    if (error.code === "auth/account-exists-with-different-credential") {
      handleAccountExistsError(error);
    }
  }
}

async function handleAccountExistsError(error) {
  const email = error.customData.email;
  console.log(email);
  const methods = await fetchSignInMethodsForEmail(auth, email);
  console.log("Sign-in methods:", methods);

  if (methods.length === 0) {
    console.log("No associated accounts found for this email.");
    return;
  }

  const pendingCredential = TwitterAuthProvider.credentialFromError(error);

  //   if (methods.includes("password")) {
  //     // Handle password sign-in and account linking
  //     const password = prompt("Please enter your password for linking:");
  //     const userCredential = await signInWithEmailAndPassword(
  //       auth,
  //       email,
  //       password
  //     );
  //     await linkWithCredential(userCredential.user, pendingCredential);
  //     console.log("Twitter account linked with password account.");
  //   } else
  if (methods.includes("google.com")) {
    // Handle Google sign-in and account linking
    const googleProvider = new GoogleAuthProvider();
    const googleResult = await signInWithPopup(auth, googleProvider);
    await linkWithCredential(googleResult.user, pendingCredential);
    console.log("Twitter account linked with Google account.");
  }
}
export function fromSecs(addSec) {
  const minutes = Math.floor(addSec / 60);
  const seconds = addSec % 60;
  if (minutes == 0) {
    return `${seconds} seconds`;
  }
  if (seconds > 0) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${minutes} minutes`;
  }
}

export function generateUniqueNumber() {
  const timePart = new Date().getTime(); // Current time in milliseconds
  const randomPart = Math.floor(Math.random() * 1000); // Random number between 0 and 999
  return timePart * 1000 + randomPart; // Combine them to increase uniqueness
}

export function generateNormalValue(mu, sigma) {
  const u1 = Math.random();
  const u2 = Math.random();
  const z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2);
  return z0 * sigma + mu;
}
export function generateLogNormalInterval() {
  const mu = Math.log(180); // Adjust this value to shift the peak
  const sigma = 0.5; // Adjust this value to increase or decrease spread
  const normal = generateNormalValue(mu, sigma);
  return Math.exp(normal); // Convert to log-normal
}
export function hideSpeechBubble() {
  const speechBubbleContainer = document.getElementById(
    "speech-bubble-container"
  );
  if (speechBubbleContainer) {
    speechBubbleContainer.classList.remove("visible");
  }
}

export function needHelpSpeechBubble() {
  setTimeout(() => {
    showSpeechBubble(
      "Do you need help? Drop us a message clicking here! We'll reply within 24h! ✌️",
      "logo",
      6000,
      "top"
    );
  }, 3 * 1000);
}
export function showSpeechBubble(
  text,
  targetElementId,
  time = null,
  tailPosition = "top"
) {
  positionSpeechBubble(text, targetElementId, tailPosition);
  const speechBubbleContainer = document.getElementById(
    "speech-bubble-container"
  );

  if (speechBubbleContainer) {
    setTimeout(() => {
      speechBubbleContainer.classList.add("visible");
      if (time) {
        setTimeout(() => {
          speechBubbleContainer.classList.remove("visible");
        }, time);
      }
    }, 100);
  }
}
export function positionSpeechBubble(text, targetElementId, tailPosition) {
  const targetEl = document.getElementById(targetElementId);
  const speechBubbleContainer = document.getElementById(
    "speech-bubble-container"
  );
  const speechBubble = speechBubbleContainer.querySelector("#speech-bubble");
  speechBubble.textContent = text;
  if (targetEl && speechBubbleContainer) {
    speechBubble.classList.remove("top", "bottom", "left", "right");
    speechBubble.classList.add(tailPosition);
    const targetRect = targetEl.getBoundingClientRect();

    // Adjust the speech bubble's position based on the tail position
    switch (tailPosition) {
      case "top":
        speechBubbleContainer.style.top = `${
          targetRect.bottom + window.scrollY - 0
        }px`;
        speechBubbleContainer.style.left = `${
          targetRect.left + window.scrollX + targetRect.width / 2 - 20
        }px`;
        break;
      case "bottom":
        speechBubbleContainer.style.top = `${
          targetRect.top + window.scrollY - speechBubbleContainer.offsetHeight
        }px`;
        speechBubbleContainer.style.left = `${
          targetRect.left + window.scrollX + targetRect.width / 2 - 20
        }px`;
        break;
      case "left":
        speechBubbleContainer.style.top = `${
          targetRect.top + window.scrollY
        }px`;
        speechBubbleContainer.style.left = `${
          targetRect.right + window.scrollX + 5
        }px`;
        break;
      case "right":
        speechBubbleContainer.style.top = `${
          targetRect.top + window.scrollY
        }px`;
        speechBubbleContainer.style.left = `${
          targetRect.left +
          window.scrollX -
          speechBubbleContainer.offsetWidth -
          5
        }px`;
        break;
      default:
        break;
    }
  } else {
    console.log("Either Target Element or Speech Bubble not found");
  }
}
import { loadStripe } from "@stripe/stripe-js";

export async function redirectToCheckout(purchase) {
  let name_endpoint = "";
  if (purchase.type === "subscription") {
    name_endpoint = "create-subscription-session";
    purchase.id = -1;
  } else if (purchase.type == "buy_time") {
    name_endpoint = "create-buy-time-checkout-session";
  } else if (purchase.type == "unlock_options") {
    name_endpoint = "create-unlock-options-checkout-session";
  }
  console.log(`name endpoint ${name_endpoint}`);
  // Replace with your public Stripe key
  const stripe = await loadStripe(`${process.env.VUE_APP_STRIPE_KEY}`);
  const token = await auth.currentUser.getIdToken(true);

  // Call your backend to create the Checkout session
  const response = await fetch(`${process.env.VUE_APP_URL}/${name_endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      id: purchase.id,
      hostname: window.location.hostname,
    }),
  });

  const session = await response.json();

  const { error } = await stripe.redirectToCheckout({
    sessionId: session.id,
  });

  if (error) {
    console.error("Error redirecting to Stripe Checkout:", error);
  }
}

export function getItemWithDefault(key, defaultValue) {
  const item = localStorage.getItem(key);
  return item !== null ? item : defaultValue;
}
