<template>
  <div id="app">
    <AppHeader @show-sign-in="showSignInPanel = true" />
    <preference-panel
      :close-on-click-out="true"
      :back-button="true"
      @show-sign-in="showSignInPanel = true"
      @show-time-or-subscribe="showTimeOrSubscribe = true"
      v-if="showPreferences"
      @close="
        startChat = true;
        showPreferences = false;
        userStore.websocket.send(JSON.stringify({ type: 'activate_chat' }));
      "
    />
    <speech-bubble ref="speechBubble" />
    <ChatInterface
      @show-preferences="showPreferences = true"
      @show-sign-in="showSignInPanel = true"
      @show-time-or-subscribe="showTimeOrSubscribe = true"
      v-if="startChat"
    />
    <sign-in-panel v-if="showSignInPanel" @close="showSignInPanel = false">
    </sign-in-panel>
    <time-or-subscribe
      @close="showTimeOrSubscribe = false"
      v-if="showTimeOrSubscribe"
      :backButton="true"
      ><span style="color: red" class="text-mid-big"
        ><strong>You are out of time!</strong></span
      >
    </time-or-subscribe>
  </div>
</template>

<script>
import { showSpeechBubble, getItemWithDefault } from "@/services/userService";
import SpeechBubble from "./components/SpeechBubble.vue";
import { useUserStore } from "@/store/userStore"; // Import your Pinia store
import PreferencePanel from "@/components/PreferencePanel.vue";
import AppHeader from "@/components/AppHeader.vue";
import ChatInterface from "./components/ChatInterface.vue";
import TimeOrSubscribe from "./components/paying_components/TimeOrSubscribe.vue";
// import config from "@/assets/shared-config.json";
import SignInPanel from "@/components/SignInPanel.vue";

export default {
  name: "App",

  mounted() {
    const userStore = useUserStore();
    (async () => {
      try {
        await userStore.initializeWebsocket();
        userStore.initializeAuth();
      } catch (error) {
        console.error("Failed to initialize websocket:", error);
      }
    })();
    // this.checkIfPreferencesSet();
    setTimeout(() => {
      showSpeechBubble(
        "Questions? Feedback? Click here to send us a quick message! 💌",
        "logo",
        6000,
        "top"
      );
    }, 1 * 60 * 1000);
    if (localStorage.getItem("custom_ai_msg_shown") == null) {
      setTimeout(() => {
        showSpeechBubble(
          "Did you know you can customize you AI companion? Try it now! 🤗",
          "new-session-btn",
          6000,
          "bottom"
        );
      }, 1 * 60 * 1000);
    }
  },

  methods: {
    getItemWithDefault,
  },
  components: {
    AppHeader,
    ChatInterface,
    PreferencePanel,
    SpeechBubble,
    SignInPanel,
    TimeOrSubscribe,
  },
  data() {
    return {
      showTimeOrSubscribe: false,
      showPreferences: false,
      startChat: true,
      userStore: useUserStore(),
      showSignInPanel: false,
    };
  },
};
</script>

<style>
@import "./styles/styles.css";
@import "./styles/spinner.css";

#app {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  text-align: center;
  gap: clamp(0px, 0.5dvh, 5px);
  color: #2c3e50;
}
</style>
