<template>
  <panel-message
    :class="userStore.isSubscribed ? 'subscribed-panel' : 'non-subsc-panel'"
    :close-on-click-out="true"
    @close="
      $emit('close');
    "
  >
    <div class="logged-in">
      You are logged in as <strong>{{ userStore.displayName }}</strong>
      <br />You talked with
      <strong>{{ userStore.getNumAIgenerated }}</strong> AIs! 😊<br />
    </div>
    <div
      v-if="locStage == 'MainProfile' && !userStore.isSubscribed"
      class="info-profile"
    >
      <div v-if="userStore.locFreeSecsLeft > 0">
        You are using your daily
        <span class="free-seconds-left">free time</span>!<br />
        You get <span class="free-seconds-left">free time</span> every day!✌️<br />
      </div>
      <div v-if="!showBuyOptsWin" class="sub-container time-left">
        <div class="sub-cont-title">
          &nbsp;You have
          <span class="tot-seconds-left">{{
            fromSecs(userStore.totSecondsLeft)
          }}</span>
          left&nbsp;
        </div>
        <div>
          <span class="free-seconds-left">{{
            fromSecs(userStore.locFreeSecsLeft)
          }}</span>
          free time
        </div>
        <div>
          <span class="paid-seconds-left">{{
            fromSecs(userStore.locPaidSecsLeft)
          }}</span>
          paid time
        </div>
      </div>
      <div
        v-if="userStore.locPaidSecsLeft == 0 && userStore.locFreeSecsLeft > 0"
      >
        After your free time is exhausted, <br />you can buy more time✨
      </div>
      <div v-if="userStore.totSecondsLeft <= 0">
        You have no time left! 😭<br /><br />Come back tomorrow for more
        <span class="free-seconds-left">free time</span>
      </div>
      <div>OR</div>
      <div class="btns" style="margin-bottom: 10px">
        <base-button
          @click="locStage = 'BuyOpts'"
          class="spend one-btn"
          icon-position="left"
          style="align-self: center"
          >⌛ Buy Time!</base-button
        >
        <base-button
          @click="locStage = 'FreeOpts'"
          class="spend one-btn"
          style="align-self: center"
          ><span style="position: relative; top: -1px">🎁</span> Get Free
          Time</base-button
        >
      </div>
    </div>
    <div v-if="userStore.isSubscribed" class="is-subscribed">
      <div class="text-mid-big">
        <strong>🤩You have UNLIMITED time!🤩</strong>
      </div>
      <div>
        Thank you for supporting <strong>{{ domainInfo.name }}</strong
        >!
      </div>
      <div>We put our 🩷 into this and we hope you'll love it too 🥰</div>
      <br />
      <div class="sub-container subscribed">
        <div class="sub-cont-title">
          <strong>🔥Your subscription gives you🔥</strong>
        </div>
        <ul style="text-align: left">
          <li>All Customization Options Unlocked</li>
          <li>Unlimited Chatting Time</li>
          <li>Early Access to New Features</li>
          <li>Better AI Memory</li>
          <li>More Slots to Save AIs</li>
          <li>
            Generate up to
            {{ sharedInfoStore.sharedInfo.default_subscribed_max_new_ai_daily }}
            AIs daily!
          </li>
        </ul>
      </div>
      <br />
      <div>
        We are open to any feedback and suggestions for new features, especially
        from our subscribed users 👌
      </div>
      <br />
      <div>
        Join our
        <a
          href="https://discord.gg/6kg5XMYtjS"
          target="_blank"
          rel="noopener noreferrer"
          class="discord-link"
        >
          <span class="discord-text"
            >Discord <i class="fa-brands fa-discord"></i
          ></span>
        </a>
        to stay in touch!
      </div>
      <br />
      <div v-if="userStore.getGracePeriodEnds">
        <strong style="color: red"
          >Your subscription will end on
          <span style="color: blue">{{ userStore.getGracePeriodEnds }}</span
          ><br />To renew it, click
          <a :href="customPortalUrl" target="_blank" rel="noopener noreferrer"
            >here</a
          ></strong
        >
      </div>
    </div>
    <time-or-subscribe
      :backButton="true"
      @close="locStage = 'MainProfile'"
      v-if="locStage == 'BuyOpts'"
    ></time-or-subscribe>
    <get-free-time
      v-if="locStage == 'FreeOpts'"
      @close="locStage = 'MainProfile'"
    />

    <base-button
      @click="goBack"
      class="close"
      :icon="' fa-solid fa-arrow-left'"
      :icon-position="'left'"
      :when-to-shrink="0"
      i
      >&nbsp;&nbsp;Back</base-button
    >

    <base-button
      @click="confirmLogout"
      class="logout"
      :icon="'fa-solid fa-sign-out-alt'"
      :when-to-shrink="0"
      :icon-position="'left'"
      >&nbsp;&nbsp;Log out
    </base-button>
    <div
      v-if="userStore.isSubscribed"
      class="subscription-details text-very-small"
    >
      Manage your subscription by clicking
      <a :href="customPortalUrl" target="_blank" rel="noopener noreferrer"
        >here</a
      >
      <br />
      All chats are completely private and secure. <br />We do not access or
      monitor them!
    </div>
    <!-- <base-button @click="closeProfile" class="close">Close</base-button></p> -->
  </panel-message>
</template>

<script>
import PanelMessage from "./PanelMessage.vue";
import { useSharedInfoStore } from "@/store/sharedInfoStore";

import BaseButton from "./BaseButton.vue";
// import BtnFreeTime from "./BtnFreeTime.vue";
// import BtnBuyTime from "./BtnBuyTime.vue";
import { fromSecs } from "@/services/userService";
import { useUserStore } from "@/store/userStore";
import TimeOrSubscribe from "@/components/paying_components/TimeOrSubscribe.vue";
import GetFreeTime from "@/components/GetFreeTime.vue";

export default {
  components: {
    BaseButton,
    // BtnBuyTime,
    TimeOrSubscribe,
    PanelMessage,
    // BtnFreeTime,
    GetFreeTime,
  },
  computed: {
    domainInfo() {
      return this.$getDomainSpecificInfo();
    },
  },
  data() {
    return {
      sharedInfoStore: useSharedInfoStore(),
      customPortalUrl: process.env.VUE_APP_CUSTOMER_PORTAL,
      userStore: useUserStore(),
      infoText: "",
      locStage: this.stage,
    };
  },
  props: {
    stage: {
      type: String,
      default: "MainProfile", // MainProfile, BuyOpts, FreeOpts
    },
    showBuyOptsWin: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    confirmLogout() {
      if (
        window.confirm(
          "Are you sure you want to log out? Chat will be disabled!"
        )
      ) {
        this.logout();
      }
    },
    logout() {
      this.goBack(); // Assume this method handles necessary cleanup
      this.userStore.logout(); // Actual logout action
    },
    displayInfo(message) {
      this.infoText = message;
    },
    fromSecs,
    goBack() {
      if (this.locStage == "MainProfile") {
        this.$emit("close");
      } else {
        this.locStage = "MainProfile";
      }
    },
  },
};
</script>

<style scoped>
.subscribed-panel ::v-deep .standard-content {
  --max-width: 50dvw;
  max-width: var(--max-width); /* Apply the variable */
}

p {
  margin-top: 0.5em;
}

.close {
  margin-top: 5px;
}

.logout {
  margin-top: 0px;
}

.info-profile {
  display: flex;
  flex-direction: column;
  gap: clamp(2px, 1dvw, 20px);
}

.btn-special ::v-deep .base-button {
  margin-left: 20px;
  margin-right: 20px;
}

.is-subscribed {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.time-left {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 20px;
}

.sub-container.subscribed {
  padding: 20px;
}
ul {
  padding-inline-start: 20px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}

.discord-link {
  text-decoration: none;
  color: white; /* Discord brand color */
  font-weight: bold;
  background-color: var(--discord-blue); /* Dark background like Discord */
  padding: 5px 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 20px;
}

.discord-link .discord-text {
  display: inline-flex;
  align-items: center;
}

.discord-link i.fa-discord {
  margin-left: 5px;
}

.discord-link:hover {
  background-color: #001aff; /* Hover background to match Discord color */
  color: #ffffff; /* White text on hover */
}

.btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: clamp(2px, 0.5dvh, 20px);
}
.one-btn {
  flex-grow: 1;
  width: 100%;
  max-width: 200px;
}
</style>
