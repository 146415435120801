// stores/chatStore.js
import { defineStore } from "pinia";
import { useUserStore } from "@/store/userStore";
import newSeshSoundFile from "../assets/new_sesh2.mp3";
import { auth } from "@/services/firebase";
import { showSpeechBubble } from "@/services/userService";

export const useChatStore = defineStore("chat", {
  state: () => ({
    messages: [],
    newSeshSound: new Audio(newSeshSoundFile),
    sessionIsRunning: false,
    messageCantGenearteNewAIs: false,
    chatIsActive: false,
    typingIndicator: null,
    timeoutBubbleFriend: null,
    isConnecting: false,
    timerBubbleFriend: null,
  }),
  actions: {
    async newSessionNewCharacter(
      random = true,
      save_previous_interaction = true
    ) {
      this.messageCantGenearteNewAIs = false;
      this.isConnecting = true;

      const token = await auth.currentUser.getIdToken(true);

      const response = await fetch(
        `${process.env.VUE_APP_URL}/api/new_session_new_character`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ai_parameters: random ? null : localStorage,
            save_previous_interaction: save_previous_interaction,
          }),
        }
      );

      const data = await response.json();
      let str_new_ai_info = "";
      if (useUserStore().isSubscribed) {
        str_new_ai_info += `As a Subscribed user, you can generate up to ${data["max_new_ai_daily"]}`;
      } else {
        str_new_ai_info += `You can generate up to ${data["max_new_ai_daily"]} AIs. Subscribe to get more!`;
      }
      if (response.ok) {
        this.isConnecting = false;
        if (data["response"] == "ok") {
          useUserStore().incrementNumAIgenerated();

          this.prepareNewSession();
          useUserStore().current_agent_id = data["persona_id"];
          useUserStore().current_agent_chat_name = data["persona_chat_name"];
          useUserStore().ai_bio_info = data["ai_bio_info"];
          let newMessage = {
            content: `<i>New connection established! ✔️
          <br>You are talking with <strong>${data["persona_chat_name"]}</strong>👋</i> `,
            sender_type: "System",
          };
          this.messages.push(newMessage);

          if (data["remaining_new_ais"] <= 10 && !useUserStore().isAnonymous) {
            let content = `<i>You can generate ${data["remaining_new_ais"]} more AIs!`;

            newMessage = {
              content: content + " " + str_new_ai_info + "</i>",
              sender_type: "System",
            };
            this.messages.push(newMessage);
          }

          this.startTimerFriendChat();
        } else if (data["response"] == "too_many_new_ais") {
          this.messageCantGenearteNewAIs = true;
        }
      }
      this.isConnecting = false;
    },
    startTimerFriendChat() {
      clearTimeout(this.timerBubbleFriend);
      if (!useUserStore().currentIsSaved && !useUserStore().isAnonymous) {
        this.timerBubbleFriend = setTimeout(() => {
          showSpeechBubble(
            "Enjoying the chat? Add them to your friends list and contact them anytime! 🤗",
            "friends-btn",
            6000,
            "top"
          );
        }, 2 * 60 * 1000);
      }
    },
    restoreSavedConvo(auto_start) {
      this.isConnecting = true;
      useUserStore().websocket.send(JSON.stringify({ type: "pause_chat" }));

      this.prepareNewSession();
      const newMessage = {
        content: `<i><strong>Last Conversation Restored!</i></strong> 🎉<br>You are talking with <strong>${
          useUserStore().current_agent_chat_name.split(" ")[0]
        }</strong>👋</i> `,
        sender_type: "System",
      };

      this.messages.push(newMessage);
      useUserStore().savedConvo.forEach((object) => {
        this.messages.push(object);
      });
      if (this.messages.length > 5 && auto_start) {
        useUserStore().websocket.send(JSON.stringify({ type: "pause_chat" }));
      } else
        useUserStore().websocket.send(
          JSON.stringify({ type: "activate_chat" })
        );
      this.startTimerFriendChat();
      this.isConnecting = false;
    },

    async newSessionWithSpecificAI(ai_id, save_previous_interaction = true) {
      this.isConnecting = true;
      useUserStore().websocket.send(JSON.stringify({ type: "pause_chat" }));

      const token = await auth.currentUser.getIdToken(true);

      const response = await fetch(
        `${process.env.VUE_APP_URL}/api/new_session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ai_id: ai_id,
            save_previous_interaction: save_previous_interaction,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data["response"] == "ok") {
          useUserStore().current_agent_images = data["persona_images"] || [];
          useUserStore().current_agent_chat_name = data["persona_chat_name"];
          this.prepareNewSession();
          //   const newMessage = {
          //     content: `<i>New connection established!</i> ✔️<br>You are talking with <strong>${
          //       useUserStore().current_agent_name.split(" ")[0]
          //     }</strong>👋</i> `,
          //     sender_type: "System",
          //   };
          //   this.messages.push(newMessage);

          useUserStore().current_agent_id = ai_id;
          useUserStore().ai_bio_info = data["ai_bio_info"];

          let newMessage = {};
          if (data["chatting_with"] == "friend") {
            newMessage = {
              content: `<i>New connection established!</i> ✔️<br>You are now chatting with <strong>${
                useUserStore().current_agent_chat_name.split(" ")[0]
              }</strong> (aka ${data["nickname"]})!</i> 💫`,
              sender_type: "System",
            };
          } else {
            newMessage = {
              content: `<i>New connection established!</i> ✔️<br>You are talking with <strong>${
                useUserStore().current_agent_chat_name.split(" ")[0]
              }</strong>👋</i> `,
              sender_type: "System",
            };
          }
          this.messages.push(newMessage);
        }
        if (data["response"] == "not_there") {
          useUserStore().websocket.send(
            JSON.stringify({ type: "activate_chat" })
          );

          const newMessage = {
            content: `<i>We could not find the requested AI!</i> ❌<br><i>It's possible it was deleted in the meantime</i> 😞`,
            sender_type: "System",
          };
          this.messages.push(newMessage);
        }
      }
      this.isConnecting = false;
    },

    prepareNewSession() {
      clearInterval(this.typingIndicator);
      this.messages = [];
      this.sessionIsRunning = true;
      this.chatIsActive = true;
      this.newSeshSound.volume = useUserStore().volumePerc / 100;
      this.newSeshSound
        .play()
        .catch((error) => console.error("Error playing sound:", error));
    },
  },
});
