<template>
  <panel-message class="preference-content">
    <div v-if="showNextSessMsg" style="color: red">
      Changes will take effect from your Next Chat!
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
      "
    >
      <strong class="text-mid-big">Your Next Chatting Partner</strong>
      <base-button
        class="spend"
        icon="fa-solid fa-check"
        icon-position="left"
        @click="checkCreate()"
        :when-to-shrink="0"
        >&nbsp;&nbsp;Create!</base-button
      >
    </div>
    <div class="sub-container">
      <div class="sub-cont-title">&nbsp;General&nbsp;</div>
      <div class="gender-selection">
        <div
          @click="
            saveToLocalStorage('Male', 'gender');
            selectedGender = 'Male';
          "
          :class="{
            'gender-selected': selectedGender === 'Male',
            'grayed-out': selectedGender && selectedGender !== 'Male',
          }"
          class="gender-option"
        >
          <img src="@/assets/male-icon.svg" alt="Male" />
          <strong>Male</strong>
        </div>
        <div
          @click="
            saveToLocalStorage('Female', 'gender');
            selectedGender = 'Female';
          "
          :class="{
            'gender-selected': selectedGender === 'Female',
            'grayed-out': selectedGender && selectedGender !== 'Female',
          }"
          class="gender-option"
        >
          <img src="@/assets/female-icon.svg" alt="Female" />
          <strong>Female</strong>
        </div>
        <div
          @click="
            saveToLocalStorage('Random', 'gender');
            selectedGender = 'Random';
          "
          :class="{
            'gender-selected': selectedGender === 'Random',
            'grayed-out': selectedGender && selectedGender !== 'Random',
          }"
          class="gender-option"
        >
          <img src="@/assets/person-icon.svg" alt="Other" />
          <strong>Random</strong>
        </div>
      </div>

      <div class="cols-container">
        <cool-dropdown
          :boxes="languagesOptions"
          :title="'🌐 Language'"
          @selected-option="saveToLocalStorage($event, 'language')"
          :firstSelectedName="
            getItemWithDefault('language', this.config.defaults.language)
          "
        />
        <cool-dropdown
          :boxes="rolesOptions"
          :title="'📜 Role'"
          @selected-option="saveToLocalStorage($event, 'role')"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'general/role')
          "
          :firstSelectedName="
            getItemWithDefault('role', this.config.defaults.role)
          "
        ></cool-dropdown>
        <cool-dropdown
          :boxes="countriesOptions"
          :title="'🌍 Country'"
          @selected-option="saveToLocalStorage($event, 'country')"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'general/country')
          "
          :firstSelectedName="
            getItemWithDefault('country', this.config.defaults.country)
          "
          >{{ categories_unlocked["general"] ? "" : "🔒" }}&nbsp;</cool-dropdown
        >
        <cool-dropdown
          :boxes="ageOptions"
          :title="'🎂 Age'"
          @selected-option="saveToLocalStorage($event, 'age')"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'general/age')
          "
          :firstSelectedName="
            getItemWithDefault('age', this.config.defaults.age)
          "
          >{{ categories_unlocked["general"] ? "" : "🔒" }}&nbsp;</cool-dropdown
        >
      </div>
    </div>
    <div class="sub-container">
      <div class="sub-cont-title">&nbsp;Appearance&nbsp;</div>
      <cool-dropdown
        class="look"
        :boxes="typePictureOptions"
        :title="'Type Picture'"
        :rowWise="false"
        @selected-option="saveToLocalStorage($event, 'type_picture')"
        :firstSelectedName="
          getItemWithDefault('type_picture', this.config.defaults.type_picture)
        "
      />
      <div class="appearence-container">
        <cool-dropdown
          class="look"
          :boxes="hairColorsOptions"
          :title="'🦰Hair Color'"
          :rowWise="false"
          @selected-option="saveToLocalStorage($event, 'hair_color')"
          :firstSelectedName="
            getItemWithDefault('hair_color', this.config.defaults.hair_color)
          "
        />
        <cool-dropdown
          class="look"
          :boxes="hairTypesOptions"
          :title="'💇‍♂️Hair Type'"
          :rowWise="false"
          @selected-option="saveToLocalStorage($event, 'hair_type')"
          :firstSelectedName="
            getItemWithDefault('hair_type', this.config.defaults.hair_type)
          "
        />
        <cool-dropdown
          class="look"
          :boxes="eyeColorsOptions"
          :title="'👁️Eye Color'"
          :rowWise="false"
          @selected-option="saveToLocalStorage($event, 'eye_color')"
          :firstSelectedName="
            getItemWithDefault('eye_color', this.config.defaults.hair_color)
          "
        />
        <cool-dropdown
          class="look"
          :boxes="buildTypesOptions"
          :title="'🏋️‍♂️Build Type'"
          :rowWise="false"
          @selected-option="saveToLocalStorage($event, 'build_type')"
          :firstSelectedName="
            getItemWithDefault('build_type', this.config.defaults.build_type)
          "
        />
        <cool-dropdown
          class="look"
          :boxes="facialShapesOptions"
          :title="'👱‍♀️Facial Shape'"
          :rowWise="false"
          @selected-option="saveToLocalStorage($event, 'facial_shape')"
          :firstSelectedName="
            getItemWithDefault(
              'facial_shape',
              this.config.defaults.facial_shape
            )
          "
        />
      </div>
    </div>

    <div class="sub-container">
      <div class="sub-cont-title">
        &nbsp;Personality&nbsp;{{
          categories_unlocked["personality"] ? "" : "🔒"
        }}&nbsp;
      </div>
      <div class="cols-container">
        <cool-dropdown
          :boxes="intelligenceOptions"
          :title="'💡 Intelligence'"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'personality')
          "
          :firstSelectedName="
            getItemWithDefault(
              'intelligence',
              this.config.defaults.intelligence
            )
          "
          @selected-option="saveToLocalStorage($event, 'intelligence')"
        />
        <!-- <cool-dropdown
          :boxes="jungianArchetypeOptions"
          :title="'🧙‍♀️ Jungian Archetype'"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'personality')
          "
          :firstSelectedName="
            getItemWithDefault(
              'jungian_archetype',
              this.config.defaults.jungian_archetype
            )
          "
          @selected-option="saveToLocalStorage($event, 'jungian_archetype')"
        /> -->

        <!-- <hr-section>Big 5</hr-section> -->
        <cool-dropdown
          class="pers-item"
          :boxes="friendlinessOptions"
          :title="'🌸 Friendliness'"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'personality')
          "
          @selected-option="saveToLocalStorage($event, 'friendliness')"
          :firstSelectedName="
            getItemWithDefault(
              'friendliness',
              this.config.defaults.friendliness
            )
          "
        />
        <cool-dropdown
          class="pers-item"
          :boxes="confidenceOptions"
          :title="'🎯 Confidence'"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'personality')
          "
          @selected-option="saveToLocalStorage($event, 'confidence')"
          :firstSelectedName="
            getItemWithDefault('confidence', this.config.defaults.confidence)
          "
        />
        <cool-dropdown
          class="pers-item"
          :boxes="playfulnessOptions"
          :title="'😜 Playfulness'"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'personality')
          "
          @selected-option="saveToLocalStorage($event, 'playfulness')"
          :firstSelectedName="
            getItemWithDefault('playfulness', this.config.defaults.playfulness)
          "
        />
        <cool-dropdown
          class="pers-item"
          :boxes="energyOptions"
          :title="'⚡ Energy'"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'personality')
          "
          @selected-option="saveToLocalStorage($event, 'energy')"
          :firstSelectedName="
            getItemWithDefault('energy', this.config.defaults.energy)
          "
        />
        <cool-dropdown
          class="pers-item"
          :boxes="moodOptions"
          :title="'🙂 Mood'"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'personality')
          "
          @selected-option="saveToLocalStorage($event, 'mood')"
          :firstSelectedName="
            getItemWithDefault('mood', this.config.defaults.mood)
          "
        />
        <cool-dropdown
          class="pers-item"
          :boxes="spiritualOrientation"
          :title="'🧘‍♂️ Spiritual Orientation'"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'personality')
          "
          @selected-option="saveToLocalStorage($event, 'spiritual_orientation')"
          :firstSelectedName="
            getItemWithDefault(
              'spiritual_orientation',
              this.config.defaults.spiritual_orientation
            )
          "
        />
        <tag-selector
          :title="'🎸 Hobbies'"
          :options="hobbyOptions"
          :max_tags="5"
          :can_type_their_own="options_unlocked['hobbies']"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'personality/hobby')
          "
          :starting-options="
            getItemWithDefault('hobbies', this.config.defaults.hobbies)
              .split(',')
              .map((hobby) => hobby.trim())
              .filter((hobby) => hobby !== '')
          "
          @selected-options="saveToLocalStorage($event, 'hobbies')"
        />

        <!-- <cool-dropdown
          class="pers-item"
          :boxes="opennessOptions"
          :title="'🎢 Openness To Experience'"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'personality')
          "
          @selected-option="saveToLocalStorage($event, 'b5openness')"
          :firstSelectedName="
            getItemWithDefault('b5openness', this.config.defaults.b5openness)
          "
        />
        <cool-dropdown
          :boxes="conscientiousnessOptions"
          class="pers-item"
          :title="'🧹 Conscientiousness'"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'personality')
          "
          @selected-option="saveToLocalStorage($event, 'b5conscientiousness')"
          :firstSelectedName="
            getItemWithDefault(
              'b5conscientiousness',
              this.config.defaults.b5conscientiousness
            )
          "
        />
        <cool-dropdown
          :boxes="extroversionOptions"
          :title="'🎉 Extroversion'"
          class="pers-item"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'personality')
          "
          @selected-option="saveToLocalStorage($event, 'b5extroversion')"
          :firstSelectedName="
            getItemWithDefault(
              'b5extroversion',
              this.config.defaults.b5extroversion
            )
          "
        />
        <cool-dropdown
          :boxes="agreeablenessOptions"
          class="pers-item"
          :title="'🤗 Agreeableness'"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'personality')
          "
          @selected-option="saveToLocalStorage($event, 'b5agreeableness')"
          :firstSelectedName="
            getItemWithDefault(
              'b5agreeableness',
              this.config.defaults.b5agreeableness
            )
          "
        />
        <cool-dropdown
          :boxes="neuroticismOptions"
          class="pers-item"
          :title="'😤Neuroticism'"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'personality')
          "
          @selected-option="saveToLocalStorage($event, 'b5neuroticism')"
          :firstSelectedName="
            getItemWithDefault(
              'b5neuroticism',
              this.config.defaults.b5neuroticism
            )
          "
        /> -->
      </div>
    </div>

    <div class="sub-container">
      <div class="sub-cont-title">
        &nbsp;Sexual Bits&nbsp;{{
          categories_unlocked["sexual"] ? "" : "🔒"
        }}&nbsp;
      </div>
      <div class="cols-container">
        <cool-dropdown
          :boxes="hornynessOptions"
          :title="'🔥 Arousal Level'"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'sexual/mood')
          "
          @selected-option="saveToLocalStorage($event, 'hornyness')"
          :firstSelectedName="
            getItemWithDefault('hornyness', this.config.defaults.hornyness)
          "
        />
        <tag-selector
          :title="'👠 Kinks'"
          :options="fetishOptions"
          :max_tags="3"
          :can_type_their_own="options_unlocked['fetishes']"
          @invalid-selection="
            userStore.isAnonymous
              ? $emit('show-sign-in')
              : openBuyPanel($event, 'sexual/kinks')
          "
          :starting-options="
            getItemWithDefault('fetishes', this.config.defaults.fetishes)
              .split(',')
              .map((hobby) => hobby.trim())
              .filter((hobby) => hobby !== '')
          "
          @selected-options="saveToLocalStorage($event, 'fetishes')"
        />
      </div>
    </div>
    <div><i>More to come soon!</i></div>
    <char-creation-options
      @close="showBuyForOption = ''"
      v-if="showBuyForOption"
      :option_to_buy="showBuyForOption"
      :option_selected="triedToSelect"
      :backButton="true"
    >
    </char-creation-options>
    <base-button
      class="btn-next spend"
      style="color: red !important"
      icon="fa-solid fa-check"
      icon-position="left"
      @click="checkCreate()"
      :when-to-shrink="0"
      >&nbsp;&nbsp;Create!</base-button
    >
  </panel-message>
</template>
<script>
// import HrSection from "./HrSection.vue";
import PanelMessage from "./PanelMessage.vue";
import CoolDropdown from "./CoolDropdown.vue";
// import CoolSlider from "./CoolSlider.vue";
import BaseButton from "./BaseButton.vue";
import { useUserStore } from "@/store/userStore";
import TagSelector from "@/components/TagSelector.vue";
import config from "@/assets/shared-config.json";
import { getItemWithDefault } from "@/services/userService.js";
import { useChatStore } from "@/store/chatStore";
import CharCreationOptions from "@/components/paying_components/CharCreationOptions.vue";

export default {
  components: {
    PanelMessage,
    CoolDropdown,
    // HrSection,
    TagSelector,
    CharCreationOptions,
    BaseButton,
    // CoolSlider,
  },
  props: {
    withBackButton: { type: Boolean, default: false },
    nextSessMessage: Boolean,
  },
  methods: {
    checkCreate() {
      if (this.userStore.isAnonymous) {
        this.$emit("show-sign-in");
        return;
      }

      if (this.userStore.totSecondsLeft <= 0 && !this.userStore.isSubscribed) {
        this.$emit("show-time-or-subscribe");
        return;
      }
      this.confirmNewCharacter();
    },
    openBuyPanel(option, attribute) {
      this.showBuyForOption = attribute;

      this.triedToSelect = option;
    },
    getItemWithDefault,
    saveToLocalStorage(option, attribute) {
      localStorage.setItem(attribute, option);
    },

    mapJsonObjs(name_option) {
      let json_option = config.levels[name_option];
      let current_selection = getItemWithDefault(
        name_option,
        config.defaults[name_option]
      );

      const isListOption = Array.isArray(config.defaults[name_option]);

      if (isListOption) {
        const currentSelectionArray = current_selection
          ? current_selection.split(",")
          : [];

        // Allow the whole list to be empty but validate non-empty elements
        const isValidList =
          currentSelectionArray.length === 0 || // Allow empty list
          currentSelectionArray.every(
            (element) =>
              element.trim() !== "" &&
              json_option.some((level) => level.name === element.trim())
          );

        if (
          !isValidList &&
          !this.options_unlocked[name_option] &&
          !currentSelectionArray.every(
            (element) =>
              element.trim() !== "" &&
              json_option.find((level) => level.name === element.trim())
                ?.free_for_all
          )
        ) {
          this.saveToLocalStorage(config.defaults[name_option], name_option);
        }
      } else {
        // Original logic for non-list options
        const isSelectionValid = json_option.some(
          (level) => level.name === current_selection
        );
        const isUnlocked = this.options_unlocked[name_option];
        // const isDefault = current_selection === config.defaults[name_option];
        const isFreeForAll = json_option.find(
          (level) => level.name === current_selection
        )?.free_for_all;

        if (!isSelectionValid || (!isUnlocked && !isFreeForAll)) {
          this.saveToLocalStorage(config.defaults[name_option], name_option);
        }
      }

      return json_option.map((level) => ({
        name: level.name, // Use the 'name' field from each object in the list
        weight: level.weight, // Weight of the level
        is_valid: this.options_unlocked[name_option]
          ? true
          : level.free_for_all, // Calculate is_valid
        is_special_visible: this.options_unlocked[name_option]
          ? true
          : level.random_for_all, // The special visibility flag
        description: level.description, // The description from the object
      }));
    },
    // addRandom(array) {
    //   return [
    //     { name: "Random", is_valid: true, is_special_visible: true },
    //     ...array,
    //   ];
    // },

    confirmNewCharacter() {
      this.chatStore.newSessionNewCharacter(false);

      this.$emit("close");
    },
  },
  setup() {
    localStorage.setItem("custom_ai_msg_shown", true);
    const userStore = useUserStore();

    const checkOptionUnlocked = (name_option) => {
      return (
        userStore.isSubscribed ||
        userStore.unlockedAll ||
        (userStore.unlockedOptions &&
          userStore.unlockedOptions.includes(config.categories[name_option]))
      );
    };
    const checkCategoryUnlocked = (categoryName) => {
      return (
        userStore.isSubscribed ||
        userStore.unlockedAll ||
        (userStore.unlockedOptions &&
          userStore.unlockedOptions.includes(categoryName))
      );
    };

    // Extract unique categories from config.categories
    const uniqueCategories = [...new Set(Object.values(config.categories))];

    // Create an object where each category is mapped to true/false (unlocked or not)
    const categories_unlocked = uniqueCategories.reduce((acc, categoryName) => {
      acc[categoryName] = checkCategoryUnlocked(categoryName);
      return acc;
    }, {});
    const options_unlocked = Object.keys(config.defaults).reduce(
      (acc, name_option) => {
        acc[name_option] = checkOptionUnlocked(name_option);
        return acc;
      },
      {}
    );

    return {
      options_unlocked,
      userStore,
      categories_unlocked,
    };
  },

  data() {
    Object.keys(config.defaults).forEach((param) => {
      localStorage.setItem(
        param,
        this.getItemWithDefault(param, config.defaults[param])
      );
    });
    return {
      panelText: "",
      triedToSelect: "",
      showBuyForOption: "",
      chatStore: useChatStore(),
      allPreferences: null,
      config: config,
      withBack: this.withBackButton,
      showNextSessMsg: this.nextSessMessage,

      // GENERAL
      selectedGender: this.getItemWithDefault("gender", "Female"),
      countriesOptions: this.mapJsonObjs("country"),
      rolesOptions: this.mapJsonObjs("role"),

      languagesOptions: this.mapJsonObjs("language"),
      ageOptions: this.mapJsonObjs("age"),

      // APPEARANCE
      typePictureOptions: this.mapJsonObjs("type_picture"),
      hairTypesOptions: this.mapJsonObjs("hair_type"),
      eyeColorsOptions: this.mapJsonObjs("eye_color"),
      hairColorsOptions: this.mapJsonObjs("hair_color"),
      buildTypesOptions: this.mapJsonObjs("build_type"),
      facialShapesOptions: this.mapJsonObjs("facial_shape"),

      // PERSONALITY
      //   jungianArchetypeOptions: this.mapJsonObjs("jungian_archetype"),
      intelligenceOptions: this.mapJsonObjs("intelligence"),
      spiritualOrientation: this.mapJsonObjs("spiritual_orientation"),
      hobbyOptions: this.mapJsonObjs("hobbies"),

      friendlinessOptions: this.mapJsonObjs("friendliness"),
      confidenceOptions: this.mapJsonObjs("confidence"),
      playfulnessOptions: this.mapJsonObjs("playfulness"),
      energyOptions: this.mapJsonObjs("energy"),
      moodOptions: this.mapJsonObjs("mood"),

      //   opennessOptions: this.mapJsonObjs("b5openness"),
      //   conscientiousnessOptions: this.mapJsonObjs("b5conscientiousness"),
      //   extroversionOptions: this.mapJsonObjs("b5extroversion"),
      //   agreeablenessOptions: this.mapJsonObjs("b5agreeableness"),
      //   neuroticismOptions: this.mapJsonObjs("b5neuroticism"),

      // SEXUAL
      fetishOptions: this.mapJsonObjs("fetishes"),
      hornynessOptions: this.mapJsonObjs("hornyness"),
    };
  },
};
</script>
<style scoped>
.sub-container {
  width: 100%;
}
.preference-content > ::v-deep .standard-content {
  --max-width: 35dvw;
}
@media (max-width: 1100px) {
  .preference-content > ::v-deep .standard-content {
    max-width: 50dvw;
  }
}

@media (max-width: 800px) {
  .preference-content > ::v-deep .standard-content {
    max-width: 60dvw;
  }
}
@media (max-width: 600px) {
  .preference-content > ::v-deep .standard-content {
    max-width: 80dvw;
  }
}
@media (max-width: 400px) {
  .preference-content > ::v-deep .standard-content {
    max-width: 90dvw;
  }
}
.sub-cont-title {
  align-self: center;
}
.gender-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0;
}

.grayed-out {
  opacity: 0.3;
}
.spend ::v-deep .base-button {
  background-image: linear-gradient(to right, #333, #f917f0) !important;
}
.row {
  display: flex;
  /* flex-wrap: wrap; */
  /* gap: clamp(10px, 1dvw, 20px); */
  width: 80%;
  align-items: center;
  justify-content: space-between;
}

.gender-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.gender-selected {
  transform: scale(1.2);
}

.gender-option:hover {
  transform: scale(1.3);
}

.gender-option img {
  width: clamp(3rem, 4.5dvw, 6rem);
  height: auto;
  margin-bottom: 10px;
}

@media (max-width: 300px) {
  .gender-option {
    margin-bottom: 10px;
  }
}

/* .create-btn ::v-deep .base-button {
  color: green;
} */
.btn-next {
  margin-top: 20px;
}

.appearence-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.cols-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: stretch;
  justify-content: center;
  /* max-width: 350px; */
  padding-left: clamp(2px, 1dvw, 25px);
  padding-right: clamp(2px, 1dvw, 25px);

  width: 100%;
}
</style>
