<template>
  <div>
    <div class="chat-interface">
      <!-- <AdComponent class="chat-ad" /> -->
      <div class="chat-window" ref="chatWindow">
        <div class="ai-images-container">
          <button
            v-if="AIimages && AIimages.length > 0"
            class="show-img-btn"
            :icon="imagesVisible ? 'fas fa-caret-up' : 'fas fa-caret-down'"
            @click="imagesVisible = !imagesVisible"
          >
            <i
              :class="{
                'fas fa-caret-up': imagesVisible,
                'fas fa-caret-down': !imagesVisible,
              }"
            ></i>
          </button>
          <div v-if="imagesVisible && !chatStore.isConnecting">
            <div v-if="AIimages && AIimages.length > 0">
              <div
                class="one-ai-image"
                v-for="(image, index) in AIimages"
                :key="index"
              >
                <img
                  class="rounded-img"
                  @click="openLightbox(image)"
                  :src="`${image}`"
                  alt="Profile Image"
                />
              </div>
            </div>
            <div v-else>
              <div class="one-ai-image image-loading-container">
                <img
                  class="rounded-img-blurred"
                  src="@/assets/default_profile.png"
                />
                <div class="loading-spinner center-spinner"></div>
                <div class="loading-text">Loading Picture</div>
              </div>
            </div>
          </div>
          <div
            class="ai-info-panel"
            v-if="!chatStore.isConnecting && imagesVisible && AIbioInfo"
          >
            <div>
              <strong
                ><span style="color: red">{{ AIbioInfo?.name }}</span
                >, {{ AIbioInfo?.age }} years</strong
              >
            </div>
            <div>
              from <strong>{{ AIbioInfo?.from }}</strong>
            </div>
          </div>
        </div>
        <div v-if="lightbox.isOpen" class="lightbox" @click="closeLightbox">
          <img :src="lightbox.currentImage" class="lightbox-image" />
        </div>
        <div v-for="(message, index) in chatStore.messages" :key="index">
          <chat-message
            :sender_type="message.sender_type"
            :content="message.content"
            :username="message.username"
          >
          </chat-message>
        </div>
        <div
          class="system-message"
          v-if="!userStore.userSessionAlready && !userStore.allReady"
        >
          <span style="opacity: 0.6">
            <i
              >Logging in... please wait a few seconds! (and if it doesn't work,
              try to refresh the page</i
            >
            🫣<i>)</i></span
          >
        </div>
        <div class="system-message" v-if="chatStore.isConnecting">
          <span style="opacity: 0.6">
            <i>Connecting with user...</i>
            <i></i
          ></span>
        </div>
        <div v-if="userStore.authReadyFromServer">
          <div v-if="!userStore.isAnonymous">
            <div v-if="!userStore.isSubscribed">
              <div v-if="userStore.totSecondsLeft <= 30">
                <div v-if="userStore.totSecondsLeft <= 0">
                  <div class="system-message">
                    <div><i>You have no time left! </i>😣</div>
                  </div>
                  <div class="system-message">
                    <div>
                      <i>Chat is disabled until you get more time</i> 🙁
                    </div>
                  </div>
                </div>
                <div v-else class="system-message">
                  <div>
                    <i
                      >You have only
                      <span class="tot-seconds-left">{{
                        fromSecs(userStore.totSecondsLeft)
                      }}</span>
                      left!</i
                    >
                    😨
                  </div>
                </div>

                <div class="system-message">
                  <base-button
                    class="spend"
                    @click="$emit('show-time-or-subscribe')"
                    style="margin: 10px 35px"
                    ><i class="fa-solid fa-clock buy-time-icon"></i
                    >&nbsp;&nbsp;Get More Time!</base-button
                  >
                </div>
              </div>
            </div>
          </div>
          <chat-message
            v-if="showChatPausedMsg && !chatStore.isConnecting"
            :sender_type="'System'"
            :content="chatPausedMessage()"
          >
          </chat-message>

          <div class="system-message sign-in-anon" v-if="userStore.isAnonymous">
            <div v-if="userStore.totSecondsLeft <= 0">
              <strong style="color: red"
                ><i>Your time as anonymous user has expired</i> 🙁</strong
              >
            </div>
            <div v-if="userStore.totSecondsLeft <= 0">
              <i>Chat is disabled until you Sign Up</i>
            </div>
            <div v-if="userStore.totSecondsLeft > 0">
              <i>As an anonymous user, you have limited time to chat</i> 😨
            </div>

            <div>
              <strong>➤&nbsp;</strong>
              <i
                ><span class="sign-in-text" @click="$emit('show-sign-in')"
                  >Sign Up</span
                >
                to unlock all features! </i
              >✨
            </div>
            <ul class="additional-info-sign-up">
              <i>
                <li>Customize your AI companion!</li>
                <li>Enjoy Free Chat Every Day!</li>
                <li>
                  Found someone interesting? Add them to your friends list and
                  chat with them whenever you want!
                </li>
                <li>AIs will remember current and past chats!</li>
                <li>Share AIs with your friends!</li></i
              >
            </ul>
          </div>
        </div>
      </div>
      <div class="chat-controls">
        <base-button
          class="control-btn"
          id="new-session-btn"
          :action="clickNewSessionRndBtn"
          :icon="
            !confirm_new_session ? 'fa-solid fa-refresh' : 'fa-solid fa-dice'
          "
          :when-to-shrink="confirm_new_session"
          :icon-position="'left'"
          :is-disabled="chatStore.isConnecting"
          >&nbsp;
          {{ confirm_new_session ? "Random" : "New" }}
        </base-button>
        <base-button
          class="control-btn"
          id="new-session-btn"
          @click="createCharacterBtn"
          :icon="'fa-solid fa-heart'"
          :when-to-shrink="0"
          :icon-position="'left'"
          v-if="confirm_new_session"
          :is-disabled="chatStore.isConnecting"
          >&nbsp;&nbsp; Create!
        </base-button>
        <input
          v-model="newMessage"
          ref="messageInput"
          @keydown="inputKeyDown"
          @keyup.enter="clickSend"
          :placeholder="firstMessageSent ? '' : 'Type a message...'"
        />
        <base-button
          class="control-btn"
          @click="clickSend"
          :is-disabled="
            userStore.totSecondsLeft <= 0 && !userStore.isSubscribed
          "
          :icon="'fa-solid fa-arrow-right'"
          >Send&nbsp;&nbsp;</base-button
        >
        <base-button
          class="control-btn"
          @click="toggleChatPause"
          v-if="sessionIsRunning"
          :is-disabled="
            (userStore.totSecondsLeft <= 0 && !userStore.isSubscribed) ||
            !sessionIsRunning
          "
          :icon="
            chatStore.chatIsActive ? 'fa-solid fa-pause' : 'fa-solid fa-play'
          "
        >
          {{ pauseResumeText }}&nbsp;&nbsp;</base-button
        >
      </div>
    </div>

    <panel-message
      :close-on-click-out="true"
      v-if="messageCantGenerate"
      @close="chatStore.messageCantGenearteNewAIs = false"
    >
      <h1>Ouch!</h1>
      <div>You've reached the limit of our daily generation capabilities😵</div>
      <div>
        You can generate up to
        <!-- TODO: should be changed with actual user max daily -->
        <strong>{{ userStore.maxNewAIdaily }}</strong>
        AIs daily!
      </div>
      <div v-if="!userStore.isSubscribed">
        <strong
          >Become a Monthly Subscriber to increase the limit to
          <span style="color: red">{{
            sharedInfoStore.sharedInfo.default_subscribed_max_new_ai_daily
          }}</span>
          AIs daily!</strong
        >
      </div>
      <subscription-container
        style="margin-top: 20px"
        v-if="!userStore.isSubscribed"
      />
      <div>You can still talk with your saved AI friends though 😊</div>
      <div class="text-small">(You've saved some of them, right? 😵)</div>

      <base-button
        @click="chatStore.messageCantGenearteNewAIs = false"
        :icon="' fa-solid fa-arrow-left'"
        :icon-position="'left'"
        :when-to-shrink="0"
        i
        >&nbsp;&nbsp;Back</base-button
      >
    </panel-message>
    <panel-message v-if="userStore.userSessionAlready">
      <h1>Ops!</h1>
      <p>
        It looks like you have another active session. For security reasons,
        please close all other tabs or browsers where you are logged in and
        refresh this page to proceed.
      </p>
    </panel-message>
    <panel-message v-if="connectionLost && !userStore.userSessionAlready">
      <h1>Connection Lost! 🫣</h1>
      <p v-if="!userStore.isAnonymous">
        This issue may occur if you closed the tab or switched tabs for an
        extended period.<br />
        <strong
          >Don't Panic! 😨<br /><span style="color: red"
            >Your conversation is saved for 30 minutes. 😌</span
          ></strong
        >
        <br />Please refresh the page to continue.
      </p>
      <p v-else>
        This issue may occur if you closed the tab or switched tabs for an
        extended period.<br />
        <br />Please refresh the page to continue.
      </p>
    </panel-message>
  </div>
</template>

<script>
// import AdComponent from "./AdComponent.vue";
import SubscriptionContainer from "./paying_components/SubscriptionContainer.vue";
import BaseButton from "./BaseButton.vue";
import PanelMessage from "./PanelMessage.vue";
import { fromSecs } from "@/services/userService";
import {
  generateLogNormalInterval,
  needHelpSpeechBubble,
} from "@/services/userService";
import ChatMessage from "./ChatMessage.vue";
import { useSharedInfoStore } from "@/store/sharedInfoStore";
// import TimeOrSubscribe from "./paying_components/TimeOrSubscribe.vue";
import { useUserStore } from "@/store/userStore";
import { useChatStore } from "@/store/chatStore";
import resumeChatSoundFile from "../assets/resume.mp3";
import stopChatSoundFile from "../assets/resume2.mp3";
import typeSoundFile from "../assets/type.mp3";
import messageSoundFile from "../assets/message_notif2.mp3";

export default {
  components: {
    BaseButton,
    ChatMessage,
    // AdComponent,
    // TimeOrSubscribe,
    SubscriptionContainer,
    PanelMessage,
  },

  computed: {
    showSubscriptionPanelWhenTimeout() {
      return (
        !this.userStore.isAnonymous &&
        this.userStore.totSecondsLeft <= 0 &&
        !this.userStore.isSubscribed &&
        !this.subPanelClosed
      );
    },
    AIimages() {
      return this.userStore.current_agent_images;
    },
    AIbioInfo() {
      return this.userStore.ai_bio_info;
    },
    messageCantGenerate() {
      return this.chatStore.messageCantGenearteNewAIs;
    },
    sessionIsRunning() {
      return this.chatStore.sessionIsRunning;
    },

    pauseResumeText() {
      return this.chatStore.chatIsActive ? "Pause" : "Resume";
    },
  },

  data() {
    return {
      sharedInfoStore: useSharedInfoStore(),
      lightbox: {
        isOpen: false,
        currentImage: null,
      },
      imagesVisible: true,
      showChatPausedMsg: false,
      confirm_new_session: false,
      firstMessageSent: false,
      showTyping: false,
      typingMessage: "",
      newMessage: "",
      //   sessionIsRunning: false,
      //   chat_is_active: false,
      userStore: useUserStore(),
      chatStore: useChatStore(),
      chat_timer: null,
      autoPaused: false,
      user_is_typing: false,
      typing_timer: null,
      doneTypingInterval: 3500, // Time in milliseconds (1 second)

      stopChatSound: new Audio(stopChatSoundFile),
      messageSound: new Audio(messageSoundFile),

      resumeChatSound: new Audio(resumeChatSoundFile),
      typeSound: new Audio(typeSoundFile),
      connectionLost: false,
      showSubscribePanel: false,
      subPanelClosed: false,
    };
  },
  watch: {
    showSubscriptionPanelWhenTimeout(newValue) {
      if (newValue) {
        this.$emit("show-time-or-subscribe");
      }
    },
    "userStore.authReadyFromServer"(newValue) {
      if (newValue) {
        this.initializeChat();
      }
    },
  },
  mounted() {
    if (this.userStore.authReadyFromServer) {
      this.initializeChat();
    }
  },
  beforeUnmount() {
    // Ensure we clean up the interval timer when the component is destroyed
    if (this.chat_timer) {
      clearInterval(this.chat_timer);
    }
    // eventBus.off('user-authenticated', this.authenticationDone);
  },

  methods: {
    // startLoadingAnimation() {
    //   let dotCount = 0;
    //   setInterval(() => {
    //     dotCount = (dotCount + 1) % 4; // Cycle between 0, 1, 2, and 3 dots
    //     this.loadingDots = ".".repeat(dotCount);
    //     this.loadingText = `Loading${this.loadingDots}`;
    //   }, 500); // Change dots every 500 milliseconds
    // },
    openLightbox(image) {
      this.lightbox.currentImage = image;
      this.lightbox.isOpen = true;
    },
    closeLightbox() {
      this.lightbox.isOpen = false;
    },
    initializeChat() {
      this.updateWebsocketOnMessages();
      this.userStore.allReady = true;
      if (!this.sessionIsRunning) {
        if (this.userStore.savedConvo) {
          this.chatStore.restoreSavedConvo(
            this.userStore.totSecondsLeft > 0 || this.userStore.isSubscribed
          );
        } else if (
          this.userStore.totSecondsLeft > 0 ||
          this.userStore.isSubscribed
        ) {
          if (this.userStore.isAnonymous) {
            this.chatStore.newSessionNewCharacter();
          } else {
            this.chatStore.newSessionNewCharacter();
          }
        }
      }
    },
    inputKeyDown() {
      clearTimeout(this.typingTimer);
      this.sendUserTyping();
    },
    sendUserTyping() {
      if (!this.user_is_typing) {
        this.user_is_typing = true;
        this.userStore.websocket.send(
          JSON.stringify({ type: "user_started_typing" })
        );
      }
      if (this.user_is_typing) {
        this.typingTimer = setTimeout(() => {
          this.sendUserStoppedTyping();
        }, this.doneTypingInterval);
      }
    },

    sendUserStoppedTyping() {
      if (this.user_is_typing) {
        this.user_is_typing = false;
        this.userStore.websocket.send(
          JSON.stringify({ type: "user_stopped_typing" })
        );
      }
    },
    chatPausedMessage() {
      if (this.autoPaused) {
        return "<div><i>Chat Automatically Paused for Inactivity. Resume whenever you want</i> ⏱️</div>";
      } else {
        return "<div><i>Chat Paused. Don't make them wait too long!</i> 👀</div>";
      }
    },
    createCharacterBtn() {
      //   if (this.userStore.totSecondsLeft <= 0 && !this.userStore.isSubscribed) {
      //     return;
      //   }
      this.$emit("show-preferences");
      this.showChatPausedMsg = true;
      this.userStore.websocket.send(JSON.stringify({ type: "pause_chat" }));
    },
    clickNewSessionRndBtn() {
      if (this.confirm_new_session) {
        if (
          this.userStore.totSecondsLeft <= 0 &&
          !this.userStore.isSubscribed
        ) {
          if (this.userStore.isAnonymous) {
            this.$emit("show-sign-in");
          } else {
            this.$emit("show-time-or-subscribe");
          }
        } else {
          this.chatStore.newSessionNewCharacter();
        }
      } else {
        document.addEventListener("click", this.handleClickAnywhere);
        // setTimeout(() => {
        //   this.confirm_new_session = false;
        // }, 2000);
      }
    },
    handleClickAnywhere() {
      // Reset confirm_new_session when any click occurs
      if (this.confirm_new_session) {
        this.confirm_new_session = false;
        document.removeEventListener("click", this.handleClickAnywhere);
      } else {
        this.confirm_new_session = true;
      }
    },
    async clickSend() {
      if (this.userStore.totSecondsLeft > 0 || this.userStore.isSubscribed) {
        if (!this.chatStore.chatIsActive) {
          this.toggleChatPause();
        }
        this.sendMessage();
      }
    },
    fromSecs,
    toggleChatPause() {
      if (this.userStore.totSecondsLeft <= 0 && !this.userStore.isSubscribed) {
        return;
      }
      this.chatStore.chatIsActive = !this.chatStore.chatIsActive;
      this.showChatPausedMsg = !this.chatStore.chatIsActive;
      if (!this.chatStore.chatIsActive) {
        this.stopChatSound.volume = this.userStore.volumePerc / 100;
        this.stopChatSound
          .play()
          .catch((error) => console.error("Error playing sound:", error));
        this.userStore.websocket.send(JSON.stringify({ type: "pause_chat" }));
      }
      if (this.chatStore.chatIsActive) {
        this.resumeChatSound.volume = this.userStore.volumePerc / 100;
        this.resumeChatSound
          .play()
          .catch((error) => console.error("Error playing sound:", error));
        this.userStore.websocket.send(
          JSON.stringify({ type: "activate_chat" })
        );
        this.autoPaused = false;
      }
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    handleStartTimer() {
      if (this.chat_timer !== null) {
        return;
      }
      this.chat_timer = setInterval(() => {
        this.userStore.decrementTime();
        if (this.locFreeSecondsLeft === 0 && this.locPaidSecondsLeft === 0) {
          clearInterval(this.chat_timer);
          this.chat_timer = null;
        }
      }, 1000);
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const chatWindow = this.$refs.chatWindow;
        if (chatWindow) {
          chatWindow.scrollTop = chatWindow.scrollHeight;
        }
      });
    },

    sendMessage() {
      if (this.newMessage.trim() !== "") {
        this.sendUserStoppedTyping();
        this.userStore.websocket.send(
          JSON.stringify({ type: "user_send_text", data: this.newMessage })
        );
        this.newMessage = ""; // Clear the input field
        this.$refs.messageInput.focus(); // Refocus the input field
        if (!this.firstMessageSent) {
          this.firstMessageSent = true; // Update the flag after the first message is sent
        }
      }
    },
    addChatMessage(msg) {
      if (this.chatStore.messages.length > 0) {
        if (msg.sender_type == "AI") {
          if (
            this.chatStore.messages[this.chatStore.messages.length - 1].isTyping
          ) {
            this.stopTypingIndicator();
          }
          this.chatStore.messages.push(msg); // Add incoming message to messages array

          this.messageSound.volume = this.userStore.volumePerc / 100;
          this.messageSound
            .play()
            .catch((error) => console.error("Error playing sound:", error));
        }
        if (msg.sender_type == "User" || msg.sender_type == "System") {
          if (
            this.chatStore.messages[this.chatStore.messages.length - 1].isTyping
          ) {
            this.chatStore.messages.splice(
              this.chatStore.messages.length - 1,
              0,
              msg
            );
          } else {
            this.chatStore.messages.push(msg);
          }
        }
      } else {
        this.chatStore.messages.push(msg);
      }
    },

    async updateWebsocketOnMessages() {
      this.userStore.websocket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type) {
          switch (data.type) {
            case "auto_pause":
              this.autoPaused = true;
              break;
            case "user_session_already":
              this.userStore.userSessionAlready = true;
              this.userStore.allReady = false;
              needHelpSpeechBubble();
              this.stopTypingIndicator();
              break;
            case "activate_chat":
              this.chatStore.chatIsActive = true;
              this.showChatPausedMsg = false;
              break;
            case "pause_chat":
              this.chatStore.chatIsActive = false;
              setTimeout(() => {
                if (!this.chatStore.chatIsActive) this.showChatPausedMsg = true;
              }, 3000);

              break;
            case "update_user_record":
              this.userStore.updateUserRecord(data.record);
              break;
            case "auth_ready":
              this.userStore.authReadyFromServer = true;
              this.userStore.savedConvo = data.saved_convo;
              this.userStore.fetchSavedPersonas();
              this.userStore.current_agent_id = data.saved_ai_id;
              this.userStore.current_agent_chat_name = data.ai_name;
              //   if we auth_ready here it means that an anonymous conversation is already going, a session is already running, so we MANUALLY need to restore a saved conversation (here) discarding the current one.
              this.userStore.current_agent_images = data.ai_images;
              this.userStore.ai_bio_info = data.ai_bio_info;
              if (this.userStore.savedConvo) {
                this.chatStore.restoreSavedConvo();
              }
              break;
            case "ai_images":
              this.userStore.current_agent_images = data.ai_images;
              break;

            case "all_time_finished": {
              break;
            }
            case "start_timer":
              this.handleStartTimer();
              break;
            case "stop_timer":
              if (this.chat_timer) {
                clearInterval(this.chat_timer);
                this.chat_timer = null;
              }
              break;
            case "update_time_left":
              this.userStore.setLocSecsLeft(data.free_secs, data.paid_secs);
              break;
            case "chat_message":
              this.addChatMessage(data.msg);
              this.scrollToBottom();
              break;
            case "typing_indicator_on":
              this.showTypingIndicator(data.name);
              this.scrollToBottom();
              break;
            case "typing_indicator_off":
              setTimeout(() => this.stopTypingIndicator(), 100);
              break;
          }
        }
      };

      this.userStore.websocket.onclose = () => {
        if (this.chat_timer) {
          clearInterval(this.chat_timer);
          this.chat_timer = null;
        }
        this.stopTypingIndicator();
        this.connectionLost = true;
        clearInterval(this.pingInterval);
      };
    },
    showTypingIndicator(name) {
      if (!this.showTyping) {
        this.typingMessage = name + " is typing";
        this.showTyping = true;
        const typingMessage = {
          content: ".",
          username: name,
          sender_type: "AI",
          isTyping: true,
        };

        this.chatStore.messages.push(typingMessage);

        clearInterval(this.chatStore.typingIndicator);
        this.clearTypingSoundTimer(); // Clear previous timeout if any

        let count = 2;
        this.chatStore.typingIndicator = setInterval(() => {
          const dots = ".".repeat(count % 4); // Use modulo 4 for sequences: 0, 1, 2, 3 dots
          this.chatStore.messages[
            this.chatStore.messages.length - 1
          ].content = `${dots}`;
          count++;
        }, 500);

        // Set up the typing sound to play at random intervals
        // this.playTypingSound();
      }
    },

    playTypingSound() {
      if (this.showTyping) {
        const typeSound = new Audio(typeSoundFile);
        typeSound.volume = Math.max(
          this.userStore.volumePerc == 0 ? 0 : 0.01,
          this.userStore.volumePerc / 100 - 0.4
        );
        typeSound
          .play()
          .catch((error) => console.error("Error playing sound:", error));

        const interval = generateLogNormalInterval(); // Random interval as previously discussed
        this.typingSoundTimer = setTimeout(() => {
          this.playTypingSound();
        }, interval);
      }
    },

    clearTypingSoundTimer() {
      clearTimeout(this.typingSoundTimer);
    },
    stopTypingIndicator() {
      if (this.showTyping) {
        this.showTyping = false;
        clearInterval(this.chatStore.typingIndicator);
        this.clearTypingSoundTimer(); // Clear the timeout for typing sound
        const typingIndex = this.chatStore.messages.findIndex(
          (msg) => msg.isTyping
        );
        if (typingIndex !== -1) {
          this.chatStore.messages.splice(typingIndex, 1);
        }
      }
    },
  },
  unmounted() {
    clearInterval(this.typingInterval);
  },
};
</script>

<style scoped>
.loading-text {
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
}
.center-spinner {
  position: absolute;
  transform: translateY(-1000%);
  left: 46%;
  top: 35%;
  color: black;
}
.loading-container {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rounded-img-blurred {
  filter: blur(10px);
  opacity: 0.7;
  border-radius: 10%;
  width: clamp(200px, 20dvw, 500px);
  height: auto;
}
.ai-info-panel {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 3px;
  background-color: rgb(255 233 202 / 83%);
  border-radius: 10px;
}
.ai-images-container {
  position: absolute;
  top: 10px;
  right: 10px;
}
.show-img-btn {
  position: absolute;
  top: 5px;
  right: 7px;
  background-color: transparent;
  margin: 0px;
  border: none;
  font-size: 15px;
}
.rounded-img {
  border-radius: 10%;
  border: 2px solid #ffa726;
  width: clamp(200px, 20dvw, 500px);
  height: auto;
}
.chat-interface {
  display: flex;
  flex-direction: column;
  height: 90dvh;
  gap: clamp(2px, 1.5dvh, 20px);
}
.chat-ad {
  position: absolute;
  top: 10px;
  width: calc(50% - 2 * clamp(5px, 3dvw, 20px));
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #ddd;
  padding: 10px;
  z-index: 1000;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.chat-window {
  position: relative;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: clamp(5px, 3dvw, 20px);
  padding-right: clamp(5px, 3dvw, 20px);
  padding-top: clamp(5px, 1dvh, 20px);
  padding-bottom: clamp(5px, 1dvh, 20px);
  background-color: var(--background-color);
  border: 1px solid #ccc;
  border-radius: 10px;
  align-items: flex-start;
  margin: 0 10px;
  overflow-y: auto;
}

.ad-wrapper {
  max-height: 50%;
  overflow: hidden;
  position: relative;
}

.ad-wrapper .adsbygoogle {
  height: 100%;
  width: 100%;
}
.chat-controls {
  margin-bottom: clamp(2px, 1.5dvh, 20px);
  display: flex;
  gap: clamp(2px, 1.5dvw, 20px);
  align-items: center;
  padding: 0 10px;
  width: 100%;
}

.control-btn {
  flex-shrink: 0;
}

input {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 50px;
  padding-top: clamp(0rem, 1.5dvh, 5rem);
  padding-right: clamp(0rem, 2.5dvw, 5rem);
  padding-bottom: clamp(0rem, 2dvh, 5rem);
  padding-left: clamp(15px, 1dvw, 5rem);
  border: 2px solid transparent;
  border-radius: 20px;
}

.system-message {
  color: black;
  display: flex;
  gap: 5px;
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start;
  flex-direction: column;
  /* font-style: italic; */
}

.system-message > div {
  text-align: left;
}

.system-message ul {
  margin-block-end: 0px;
  margin-block-start: 0px;
  /* padding-inline-start: 0px; */
}
.conn-disabled {
  display: flex;
  flex-direction: row;
}

.sign-in-text {
  cursor: pointer;
  color: var(--accent-color);
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.sign-in-anon {
  border: 2px solid black;
  border: 2px solid #ccc;

  border-radius: 10px;
  margin: clamp(2px, 3dvw, 5px);
  padding: 10px;
  padding-left: clamp(2px, 3dvw, 15px);
  padding-right: clamp(2px, 3dvw, 15px);
}
.sign-in-text:hover,
.sign-in-text:focus {
  color: #0056b3;
  text-decoration: underline;
}

.buy-time-icon {
  color: yellow;
}

@media (max-height: 600px) {
  .additional-info-sign-up {
    display: none;
  }
  .rounded-img {
    width: clamp(150px, 10dvw, 500px);
    height: auto;
  }
}

@media (max-height: 400px) {
  .ai-info-panel {
    display: none;
  }
}
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it's above other content */
}

.lightbox-image {
  max-width: 90%;
  max-height: 90%;
}
.loading-spinner {
  border: 4px solid var(--accent-color);
  border-top: 4px solid var(--accent-color2);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
